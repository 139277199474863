<template>
    <footer id="footer-main">
        <div class="container fullWidth_Container">
            <div class="row align-items-center">
                <div class="col-md-3">
                    <div class="leftBox_Footer d-flex justify-content-center">
                        <ul class="social-link">
                            <li><a href="https://www.facebook.com/NMRestaurantAssociation" target="_blank"><img src="img/signup/facebook.svg"></a></li>
                            <li><a href="https://twitter.com/NMRestaurants" target="_blank"><img src="img/signup/twitter.svg"></a></li>
                            <li><a href="https://www.linkedin.com/company/new-mexico-restaurant-association/" target="_blank"><img src="img/signup/linkedin.svg"></a></li>
                            <li><a href=" https://www.youtube.com/user/NMRA1946" target="_blank"><img src="img/signup/you-tube.svg"></a></li>
                            <li><a href="https://www.instagram.com/nm_dining/" target="_blank"><img src="img/signup/insta.svg"></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="rightBox_Footer d-flex justify-content-center">
                        <ul class="footer-link">
                            <li>9201 Montgomery NE - Suite 602</li>
                            <li>Albuquerque, New Mexico, NM 87111</li>
                            <li>Phone: 505-343-9848</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {}
</script>
<style scoped>
ul.social-link, ul.footer-link {
    display: flex;
    list-style: none;
    padding: 0;
}

ul.social-link li {
    margin: 0px 5px;
}

ul.footer-link li {
    margin: 0px 5px;
}
.mra-login .footer-link li {
    color: #d7d7d7;
    font-size: 14px;
}

body {
    background-color: #f3f3f3 !important;
}

#footer-main {
    border-top: 5px solid #b73e23;
    height: auto;
    padding-top: 25px;
    /* position: absolute; */
}

footer {
    background-color: #F3F3F3;
    padding: 15px 0;
    width: 100%;
    bottom: 0;
}
.footer-link {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
}
.footer-link li:not(:last-child) {
    margin-right: 15px;
}
.footer-link li a {
    color: #8A8686;
    font-size: 14px;
}
.footer-link li a:hover {
    color: #EF8F1D;
}


@media (max-width:991px) {
    .footer-link {
    justify-content: flex-start;
}
.container.fullWidth_Container > div[data-v-7b7d7750] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 12px;
    text-align: center;
}
.container.fullWidth_Container > ul {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    text-align: center;
}
ul.footer-link[data-v-7b7d7750] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    padding: 0;
    flex-direction: column;
}


}
@media (max-width:768px) {
    .fullWidth_Container {
        max-width: 95%;
    }

    .rightBox_Footer .footer-link {
        justify-content: center;
        flex-direction: column;
    }
    .container.fullWidth_Container > div {
    flex-direction: column;
    font-size: 12px;
    text-align: center;
}
ul.social-link{
    margin-bottom: 20px;
}
}
@media (max-width:420px) {
    .container.fullWidth_Container > div {
    flex-direction: column;
    font-size: 12px;
    text-align: center;
}
ul.social-link{
    margin-bottom: 10px;
}

}
</style>
