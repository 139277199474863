<template>
    <div class="header-section">
        <div class="logo">
        <router-link to="/nmra-login" class="logo-img">
            <img src="/nmra/NMRA_Logo.png">
        </router-link>
        <a href="/" class="navbar-brand">
            <img :src="logo" />
        </a>
    </div>
        <div class="toplogin-btn">
            <router-link to="/nmra-login" class="login-text">
                <button class="btn ctm-btn">Login</button>
            </router-link>
            <router-link to="/nmra-signup" class="login-text">
                <button class="btn ctm-btn">Sign up</button>
            </router-link>
        </div>
        <div class="container">
            <div class="header-body text-center">
                <div class="row justify-content-center"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted: function () {
        document.querySelector('title').textContent = "NMRA";
    }
}
</script>
<style scoped>
.ctm-btn {
    background-color: #b73e23 !important;
    border: 0px;
    border-radius: 0% !important;
    color: #FFFFFF;
}
.login-text {
    position: unset !important;
    font-weight: 700;
}

.logo-img img {
    margin: 5px 0;
    margin-left: 50px;
    height: 80px;
}
.header-section {
    /* width: 100vw !important; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-section > div {
    width: fit-content !important;
}

.toplogin-btn {
    position: absolute;
    right: 20px;
}

.body > div {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .header-section {
        height: auto !important;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .toplogin-btn {        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .toplogin-btn > a{
        position: unset !important;
    }
    
    .logo {
        width: 50%;
    }
 
}

</style>